import React, { Component } from "react";
import { msalInstance } from "..";
import {jwtDecode} from 'jwt-decode'
import moment from "moment";
import { AUTH, BASE_URL, REFRESH } from "../constant/apiEndpoints";

export default class APIService {
  constructor() {
    this.error = false;
    this.results = {};
  }

  async refreshToken(tokens) {
    let url = BASE_URL + AUTH + REFRESH
    await fetch(url, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + tokens.access,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({'refresh': tokens.refresh})
    }).then(response => {
      this.status = response.status;
      return response.json();
    })
    .then(jsonResponse => {
      this.results = jsonResponse;
    })
    .catch(e => {
      console.log(e);
    });

    if (this.status !== 200) {
      this.error = true;
      this.checkErrors();
    }

    let data = {...this.results, user: tokens.user}
    localStorage.setItem('access_token', JSON.stringify(data))

    return data
  }

  /* Get headers for the request */
  async getHeaders(type) {
    let common_headers = {
      'X-Content-Type-Options': "nosniff",
      'X-Frame-Options': "sameorigin",
      'X-XSS-Protection': "1; mode=block",
      'Referrer-Policy': "same-origin",
      'Feature-Policy': "microphone 'none'; camera 'none'; geolocation 'none'",
      'Content-Security-Policy': "default-src 'self'"
    };
    let headers = {}
    headers = { ...common_headers }

    let access_token = localStorage.getItem("access_token")
    if (access_token !== null) {
      access_token = JSON.parse(access_token)
      const user = jwtDecode(access_token.access)
      const isExpired = moment.unix(user.exp).diff(moment()) < 1

      if (isExpired) {
        access_token = await this.refreshToken(access_token)
      }

      headers = {
        ...headers,
        Accept: "application/json",
        Authorization: "Bearer " + access_token.access
      }
    }
    if (type !== "form-data") {
      let contentType = { "content-type": "application/json" };
      headers = { ...headers, ...contentType };
    } else {
      headers = {
        ...headers,
        Accept: "application/json"
      }
    }
    return headers;
  }

  getAttrName(key) {
    let parts = key.split("_");
    let newKey = "";
    for (let part in parts) {
      newKey =
        newKey +
        " " +
        parts[part].charAt(0).toUpperCase() +
        parts[part].slice(1);
    }
    return newKey;
  }

  getMessage(obj) {
    let data = obj;
    let msg = "";
    if (obj.constructor == Object) {
      for (let key in data) {
        msg = msg + this.getAttrName(key) + " : " + this.getMessage(data[key]) + "";
      }
    } else if (obj.constructor == Array) {
      for (let key in data) {
        msg = msg + data[key] + "\t\n";
      }
    } else {
      msg = msg + obj + "\t\n";
    }
    return String(msg);
  }

  /* Checking for errors if any */
  checkErrors(response) {
    this.error = true;
    // var message = "";
    switch (this.status) {
      case 400:
        this.results = {
          message: this.getMessage(this.results)
        };
        break;
      case 401:
        this.results = {
          message: "Unauthorized Access"
        };

        msalInstance.logoutRedirect().catch(e => {
            console.error(e);
        });
        localStorage.removeItem("access_token");
        break;
      case 403:
        this.results = {
          message: "Permission Denied"
        };
        break;
      case 404:
        this.results = {
          message: "Results not found."
        };
        break;
      default:
        this.results = {
          message: "Something went wrong"
        };
    }
  }

  /* GET method call with fetch */
  async get(url) {
    const headers = await this.getHeaders()
    await fetch(url, {
      method: "GET",
      headers: headers
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (this.status !== 200) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      results: this.results,
      status: this.status
    };
  }

  /* POST method call with fetch */
  async post(url, data, type) {
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    const headers = await this.getHeaders(type)
    await fetch(url, {
      method: "POST",
      body: data,
      headers: headers
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (!(this.status === 201 || this.status === 200)) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      status: this.status,
      results: this.results
    };
  }

  /* PUT method call with fetch */
  async put(url, data, type) {
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    const headers = await this.getHeaders(type)
    await fetch(url, {
      method: "PUT",
      body: data,
      headers: headers
    })
      .then(response => {
        this.status = response.status;

        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (this.status !== 200) {
      this.error = true;
      this.checkErrors();
    }

    return {
      error: this.error,
      results: this.results,
      status: this.status
    };
  }

  /* PATCH method call with fetch */
  async patch(url, data, type) {
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    const headers = await this.getHeaders(type)
    await fetch(url, {
      method: "PATCH",
      body: data,
      headers: headers
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (this.status !== 200) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      results: this.results,
      status: this.status
    };
  }

  /* DELETE method call with fetch */
  async delete(url, data, type) {
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    const headers = await this.getHeaders(type)
    await fetch(url, {
      method: "DELETE",
      body: data,
      headers: headers
    })
      .then(response => {
        this.status = response.status;
        if (this.status !== 204) {
          return response.json();
        }
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (this.status !== 204) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      results: this.results,
      status: this.status
    };
  }

  /* POST method call with fetch */
  async postAnonymous(url, data, type) {
    if (type !== "form-data") {
      data = JSON.stringify(data);
    }
    await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
        "content-type": "application/json"
      }
    })
      .then(response => {
        this.status = response.status;
        return response.json();
      })
      .then(jsonResponse => {
        this.results = jsonResponse;
      })
      .catch(e => {
        console.log(e);
      });
    if (!(this.status === 201 || this.status === 200)) {
      this.error = true;
      this.checkErrors();
    }
    return {
      error: this.error,
      status: this.status,
      results: this.results
    };
  }
}
