import React, { useEffect } from "react";
import { PageLayout } from "./components/pageLayout";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routing/routes";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import InitialRoute from "./pages/initialRoute";
import Login from "./pages/login";
// import { DBNAME } from "./config/configVariables";
// const { InteractiveBrowserCredential, DefaultAzureCredential, ChainedTokenCredential, ManagedIdentityCredential, ClientSecretCredential } = require("@azure/identity");
// const { SecretClient } = require("@azure/keyvault-secrets");
// const { ServiceBusClient } = require("@azure/service-bus");
const { ClientSecretCredential } = require("@azure/identity");
const { SecretClient } = require("@azure/keyvault-secrets");

function App() {
  return (
    <Router>
      <AuthenticatedTemplate>
        {/* <p>You are signed in!</p> */}
        <Routes />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* <p>You are sign out in!</p> */}
        <Login />
      </UnauthenticatedTemplate>
    </Router >
  );
}

export default App;